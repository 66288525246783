import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubRequest,
  orgSubscriptionHistoryRequest,
  subAutoRenewToggleRequest,
  trySubscriptionRequest,
} from "../../../redux/features/subscription/subscriptionSlice";
import moment from "moment";
import global from "../../../redux/constants/global";
import Cookies from "js-cookie";
import {
  QuantityInput,
  calculateTotalAmount,
  subscriptionPlans,
} from "../../utils/helpers";
import Calendar from "../../../assets/calnedar.svg";
import { FaSpinner } from "react-icons/fa";
import { BsCalendarEvent } from "react-icons/bs";
import moneybill from "../../../assets/money bill.svg";
import moneyBillRed from "../../../assets/moneyBillRed.svg";

export default function SubOverviewComp(props) {
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [cancelAutorenewalModal, setCancelAutorenewalModal] = useState(false);
  const [resubModal, setResubModal] = useState(false);
  const [subId, setSubId] = useState("");
  const [subName, setSubName] = useState("");
  const [planId, setPlanId] = useState("");
  const [daysLeft, setDaysLeft] = useState(0);
  const [subProgressCount, setSubProgressCount] = useState(0);
  const [teamQuantity, setTeamQuantity] = useState(0);
  const [convoQuantity, setConvoQuantity] = useState(0);
  const [convocount, setConvoCount] = useState(0);
  const isMounted = useRef(false);

  const routeState = useSelector((state) => state.routeReducer);

  const planName = routeState.planName;

  // console.log(planName);

  const unitTeamCost = 20;
  const unitConvoCost =
    planName === subscriptionPlans.BASIC
      ? 12
      : planName === subscriptionPlans.ADVANCED
      ? 24
      : planName === subscriptionPlans.PROFESSIONAL
      ? 40
      : 0;

  const subscriptionState = useSelector((state) => state.subscriptionReducer);
  const profileState = useSelector((state) => state.profileReducer);
  const setdate = subscriptionState?.organisationPlans?.data;

  let dispatch = useDispatch();
  let orgId = Cookies.get("orgId") || "";

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    getActivePlan();
  }, []);

  let getActivePlan = () => {
    dispatch(orgSubscriptionHistoryRequest())
      .then((res) => {
        if (res?.payload?.status !== 200) {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let cancelSub = () => {
    let data = {
      subscription_id: subId,
    };

    dispatch(cancelSubRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          getActivePlan();
          setCancelSubModal(false);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  let cancelAutoRenew = () => {
    let data = {
      subscription_id: subId,
    };

    dispatch(subAutoRenewToggleRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          getActivePlan();
          setCancelAutorenewalModal(false);
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };
  const totalCheckoutAmount =
    calculateTotalAmount(unitTeamCost, teamQuantity) +
    calculateTotalAmount(unitConvoCost, convocount);

  const QuantityInput = ({
    handleDecrement,
    handleIncrement,
    quantity,
    totalAmount,
  }) => {
    return (
      <div
        className="counters"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            onClick={handleDecrement}
            style={{
              cursor: "pointer",
              height: "22px",
              width: "22px",
              borderRadius: "22px",
              color: "#6261c4",
              border: "none",
              fontWeight: "bolder",
              fontSize: "15px",
              backgroundColor: "white",
              boxShadow: "0px 0px 0px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            -
          </button>
          <span
            style={{
              margin: "0 10px",
              fontSize: "18px",
              fontWeight: "bolder",
              color: "#6261c4",
            }}
          >
            {quantity}
          </span>
          <button
            onClick={handleIncrement}
            style={{
              height: "22px",
              width: "22px",
              cursor: "pointer",
              borderRadius: "22px",
              color: "#6261c4",
              border: "none",
              fontWeight: "bolder",
              fontSize: "15px",
              backgroundColor: "white",
              boxShadow: "0px 0px 0px 2px rgba(0, 0, 0, 0.1)",
            }}
          >
            +
          </button>
          <span
            style={{ marginLeft: "", display: "flex", justifyContent: "end" }}
          ></span>
        </div>

        <div>
          <span
            style={{
              color: "#6261c4",
              border: "none",
              fontWeight: "bolder",
              fontSize: "15px",
            }}
          >
            Total: ${totalAmount?.toFixed(2).toLocaleString()}
          </span>
        </div>
      </div>
    );
  };

  const filters = subscriptionState?.subHistory?.data?.filter(
    (value, i) => value?.is_active === true
  );

  useEffect(() => {
    if (filters && filters?.length > 0) {
      const currentStart = filters[filters?.length - 1].start_date;
      const currentExp = filters[filters?.length - 1].expiration_date;

      const startDate = new Date(currentStart);
      const endDate = new Date(currentExp);
      const dateObj = new Date();
      const todayDate = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate()
      );
      const diff1 =
        Math.ceil(Math.abs(todayDate - endDate) / (1000 * 60 * 60 * 24)) - 1;
      const diff2 = Math.ceil(
        Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
      );
      const used = (((diff2 - diff1) / diff2) * 100).toFixed();
      setDaysLeft(diff1);
      setSubProgressCount(used);
    }
  }, [subscriptionState?.subHistory?.data]);

  let resubscribe = () => {
    let data = {
      organization_id: orgId,
      success_url: global.appBaseUrl + "Subscription?success",
      failed_url: global.appBaseUrl + "Subscription?failed",
      plan_id: planId,
    };
    dispatch(trySubscriptionRequest(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          window.location.href = res?.payload?.data?.payment_url;
        } else {
          toast.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="sub-overview-area ">
      {cancelSubModal && (
        <div className="main-modal flex justify-center items-center">
          <div className="main-modal-content w-[35%]  bg-white py-10 px-4  ">
            <div className=" rounded-lg">
              <div className="">
                <div className="flex gap-6">
                  <div>
                    <img src={moneyBillRed} alt="moneyBill" />
                  </div>
                  <div>
                    <h5 className="font-PJ-medium text-2xl leading-9 tracking-[-0.8%]">
                      <h5>Cancel Subscription</h5>
                    </h5>
                    <div className="mt-3 font-PJMedium text-lg leading-[27px]  text-[#5f5f5f] ">
                      <p>
                        You are about to cancel your {subName} subscription
                        plan.
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="mt-10 mb-4" />
                <div className="flex justify-center gap-6">
                  <button
                    onClick={() => setCancelSubModal(false)}
                    className=" border border-[#b5b5b5] font-inter text-base leading-[19.36px] tracking-[-4%] rounded-lg w-[186px] py-2"
                  >
                    Close
                  </button>

                  <button
                    className="bg-[#E80000] py-2 w-[186px] font-inter text-base leading-[19.36px] tracking-[-4%] text-white rounded-lg "
                    onClick={cancelSub}
                  >
                    {subscriptionState.updateIsLoading ? (
                      <div className="flex items-center justify-center">
                        <FaSpinner className="animate-spin " />

                        <span className="sr-only"> Loading...</span>
                      </div>
                    ) : (
                      "Cancel Subscription"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {cancelAutorenewalModal && (
        <div className="main-modal flex justify-center items-center">
          <div className="main-modal-content  bg-white py-10 px-4 ">
            <div className="flex gap-6">
              <div>
                <img src={moneybill} alt="moneyBill" />
              </div>
              <div>
                <h5 className="font-PJ-medium text-2xl leading-9 tracking-[-0.8%]">
                  <h5>Cancel Autorenewal</h5>
                </h5>
                <div className="mt-3 font-PJ-regular text-lg leading-[27px]  text-[#5f5f5f] ">
                  <p>
                    You are about to cancel autorenewal on your <br /> {subName}{" "}
                    subscription plan.
                  </p>
                </div>
              </div>
            </div>

            <hr className="mt-10 mb-4" />
            <div className="flex justify-center gap-6">
              <button
                onClick={() => setCancelAutorenewalModal(false)}
                className=" border border-[#b5b5b5] font-inter text-base leading-[19.36px] tracking-[-4%] rounded-lg w-[186px] py-2"
              >
                Close
              </button>

              <button
                className="bg-primary  py-2 w-[186px] font-inter text-base leading-[19.36px] tracking-[-4%] text-light rounded-lg "
                onClick={cancelAutoRenew}
              >
                {subscriptionState.updateIsLoading ? (
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin " />

                    <span className="sr-only"> Loading...</span>
                  </div>
                ) : (
                  "Cancel autorenewal"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {resubModal && (
        <div className="main-modal  flex justify-center items-center">
          <div className="main-modal-content w-[35%]  bg-white py-10 px-4 ">
            <div className="flex gap-6">
              <div>
                <img src={moneybill} alt="moneyBill" />
              </div>
              <div>
                <h5 className="font-PJ-medium text-2xl leading-9 tracking-[-0.8%]">
                  <h5>Resubscribe</h5>
                </h5>
                <div className="mt-3 font-PJ-regular text-lg leading-[27px] tracking-[-0.32%] text-[#5f5f5f] ">
                  <p>
                    You are about to resubscribe to {subName} subscription plan
                    at the the price of{" "}
                    {profileState?.profileInfo?.data?.currency} per month ..
                  </p>
                </div>
              </div>
            </div>

            <hr className="mt-10 mb-4" />
            <div className="flex justify-center gap-6">
              <button
                onClick={() => setResubModal(false)}
                className=" border border-[#b5b5b5] font-inter text-base leading-[19.36px] tracking-[-4%] rounded-lg w-[186px] py-2"
              >
                Close
              </button>

              <button
                className="bg-primary py-2 w-[186px] font-inter text-base leading-[19.36px] tracking-[-4%] text-light rounded-lg "
                onClick={resubscribe}
              >
                {subscriptionState.updateIsLoading ? (
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin " />

                    <span className="sr-only"> Loading...</span>
                  </div>
                ) : (
                  "Resubscribe"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {subscriptionState?.subHistory?.data?.map((val, i) => {
        if (
          val?.is_active ||
          (val?.payment_status === "Paid" && val?.is_expired === false)
        ) {
          console.log(val.is_active, val?.payment_status);
          return (
            <div className=" mt-4" key={i}>
              <div className=" pt-5">
                <div className="flex justify-between items-center border-[0.4px] shadow-current border-[##D9D9D999] border-opacity-[60%] px-8 py-5 rounded-lg bg-white ">
                  <div className="flex gap-32">
                    <div className="">
                      <p className="font-PJ-regular text-base leading-[20.16px] tracking-[-4%]">
                        Current Plan
                      </p>
                      <h5 className="font-inter text-lg leading-[21.78px] tracking-[-4%] ">
                        {val?.subscription_plan_id?.name}
                      </h5>
                    </div>
                    <div className="">
                      <p className="font-PJ-regular text-base leading-[20.16px] tracking-[-4%]">
                        Payment
                      </p>
                      <h5 className="font-inter text-lg leading-[21.78px] tracking-[-4%]">
                        {" "}
                        {profileState?.profileInfo?.data?.currency}{" "}
                        {val?.price.toLocaleString()} <span>per month</span>
                      </h5>
                    </div>
                  </div>
                  <div className="flex gap-6 items-center ">
                    {(planName === subscriptionPlans.PROFESSIONAL ||
                      planName === subscriptionPlans.BASIC ||
                      planName === subscriptionPlans.ADVANCED) && (
                      <p
                        className="cursor-pointer text-[#1B0143] font-PJ-bold text-sm leading-[16.94px] tracking-[-4%] "
                        onClick={() => {
                          setSubId(val?.subscription_id);
                          setSubName(val?.subscription_plan_id?.name);
                          setCancelSubModal(true);
                        }}
                      >
                        Cancel subscription
                      </p>
                    )}
                    <button
                      onClick={() => props?.changePage("plans")}
                      className="bg-primary text-light font-inter text-sm leading-[20.3px] tracing-[-2%] rounded-[10px] py-2 px-5"
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              </div>
              <div className=" py-4">
                <div className=" ">
                  <div className=" mb-4  border-[0.4px] shadow-custom border-[#D9D9D999] border-opacity-[60%] bg-white rounded-lg px-3 py-4">
                    <div className=" flex justify-between items-center px-4">
                      <div>
                        <p className="mb-3  font-PJ-regular text-base leading-[20.16px] tracking-[-4%]">
                          Subscription Used
                        </p>
                        <div className=" bg-[#E8E8E8] rounded-lg !w-[435px] h-[10px] ">
                          <div
                            className="rounded-lg h-[10px] bg-primary  "
                            style={{
                              width: `${subProgressCount}%`,
                            }}
                          ></div>
                        </div>
                        <div className="">
                          <div className="flex gap-1 mt-2">
                            <p className="font-inter text-lg leading-[21.78px] tracking-[-4%]">
                              {Math.ceil(subProgressCount)}% Used
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="">
                          <p
                            onClick={() => props?.changePage("plans")}
                            className="cursor-pointer border border-light font-inter text-sm leading-[20.3px] tracing-[-2%] rounded-[10px] px-5 py-2 "
                          >
                            Upgrade
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" py-3 px-4  border-[0.4px] shadow-custom border-[#D9D9D999] border-opacity-[60%] bg-white rounded-lg ">
                    <div className=" flex justify-between px-4 items-center  ">
                      <div>
                        <p className="font-PJ-regular text-base leading-[20.16px] tracking-[-4%]">
                          Expiration
                        </p>
                        <div className="!w-[435px] h-[10px] mt-4 bg-[#f1f3f5] rounded-lg">
                          <div
                            className="rounded-lg  h-[10px]  bg-primary"
                            style={{
                              width: `${subProgressCount}%`,
                            }}
                          ></div>
                        </div>
                        <div className="mt-3">
                          <div className="">
                            <div className="flex items-center gap-2">
                              <img src={Calendar} alt="calendar" />
                              {/* <p className='mb-0 pb-0'>{getRemainingDays(moment(val?.expiration_date).format('L'))}days to expiration</p> */}
                              {daysLeft < 1 ? (
                                <p className="mb-0 pb-0">
                                  Your subscription plan has expired
                                </p>
                              ) : (
                                <p className="mb-0 pb-0 font-inter text-lg leading-[21.78px] tracking-[-4%]">
                                  {daysLeft} days to expiration
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {(planName === subscriptionPlans.PROFESSIONAL ||
                        planName === subscriptionPlans.BASIC ||
                        planName === subscriptionPlans.ADVANCED) && (
                        <div className="flex  items-center gap-6">
                          <div className="">
                            <div className="text-end">
                              <p
                                className="cursor-pointer text-[#1B0143] font-PJ-bold text-sm leading-[16.94px] tracking-[-4%]"
                                onClick={() => {
                                  setSubId(val?.subscription_id);
                                  setSubName(val?.subscription_plan_id?.name);
                                  setCancelAutorenewalModal(true);
                                }}
                              >
                                {val?.auto_renewal
                                  ? "Cancel autorenewal"
                                  : "Enable autorenewal"}
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <button
                              className="bg-primary rounded-lg py-2 font-inter text-sm leading-[20.3px] tracking-[-2%] px-2 text-light cursor-pointer "
                              onClick={() => {
                                setPlanId(val?.subscription_plan_id?.plan_id);
                                setSubName(val?.subscription_plan_id?.name);
                                setResubModal(true);
                              }}
                            >
                              Resubscribe
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      <div>
        {/* {isPlanActive === true && (
          <div
            style={{ justifyContent: "space-between" }}
            className="card-deck d-flex gap-4 mt-4"
          >
            <div className="d-flex gap-4">
              <div
                style={{ height: "200px", width: "290px" }}
                className="cards "
              >
                <div className="card-body">
                  <h5 className="card-title">Add team member</h5>
                  <div className="  gap-2" style={{ marginTop: "20px" }}>
                    <hr />
                    <p className="extraP">Team member add-on</p>

                    <QuantityInput
                      quantity={teamQuantity}
                      handleIncrement={() => setTeamQuantity(teamQuantity + 1)}
                      handleDecrement={() =>
                        teamQuantity > 0 ? setTeamQuantity(teamQuantity - 1) : 0
                      }
                      totalAmount={calculateTotalAmount(
                        unitTeamCost,
                        teamQuantity
                      )}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ height: "200px", width: "330px" }}
                className="cards "
              >
                <div className="card-body">
                  <h5 className="card-title" style={{ borderBottom: "1px" }}>
                    Add more conversations
                  </h5>
                  <hr />
                  <p className="extraP">
                    Conversations add-on &nbsp;
                    <span style={{ fontWeight: "16px" }} className="">
                      {" "}
                      ( per{" "}
                      {planName === subscriptionPlans.BASIC
                        ? 100
                        : planName === subscriptionPlans.ADVANCED
                        ? 500
                        : planName === subscriptionPlans.PROFESSIONAL
                        ? 1000
                        : 0}
                      )
                    </span>
                  </p>
                  <QuantityInput
                    quantity={convoQuantity}
                    handleIncrement={() => {
                      setConvoQuantity(
                        convoQuantity +
                          (planName === subscriptionPlans.BASIC
                            ? 100
                            : planName === subscriptionPlans.ADVANCED
                            ? 500
                            : planName === subscriptionPlans.PROFESSIONAL
                            ? 1000
                            : 0)
                      );
                      setConvoCount(convocount + 1);
                    }}
                    handleDecrement={() => {
                      convoQuantity > 0 &&
                        setConvoQuantity(
                          convoQuantity -
                            (planName === subscriptionPlans.BASIC
                              ? 100
                              : planName === subscriptionPlans.ADVANCED
                              ? 500
                              : planName === subscriptionPlans.PROFESSIONAL
                              ? 1000
                              : 0)
                        );
                      convoQuantity > 0 && setConvoCount(convocount - 1);
                    }}
                    totalAmount={calculateTotalAmount(
                      unitConvoCost,
                      convocount
                    )}
                  />
                </div>
              </div>
            </div>
            {(convoQuantity > 0 || teamQuantity > 0) && (
              <div className="cards ">
                <div className="card-body">
                  <h5 className="card-title">Order Summary</h5>
                  <ul style={{ marginBottom: "10px" }} className="list-group ">
                    {teamQuantity > 0 && (
                      <li
                        style={{ fontWeight: "bold" }}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        Teammate add-on x{teamQuantity}
                        <span
                          style={{ backgroundColor: "#6261c4" }}
                          className="badge rounded-pill"
                        >
                          {" "}
                          ${calculateTotalAmount(unitTeamCost, teamQuantity)}
                        </span>
                      </li>
                    )}
                    {convoQuantity > 0 && (
                      <li
                        style={{ fontWeight: "bold" }}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        Conversations add-on x{convoQuantity}
                        <span
                          style={{ backgroundColor: "#6261c4" }}
                          className="badge  rounded-pill"
                        >
                          $
                          {calculateTotalAmount(
                            unitConvoCost,
                            convocount
                          ).toLocaleString()}
                        </span>
                      </li>
                    )}
                  </ul>
                  <div style={{ fontWeight: "bold", marginTop: "40px" }}>
                    <h6>Total:</h6>
                    <h4 style={{ color: "#6261c4" }}>
                      ${totalCheckoutAmount.toFixed(2).toLocaleString()}
                    </h4>
                  </div>
                  <button
                    style={{ fontWeight: "bold", marginTop: "20px" }}
                    className="checkoutCardButton"
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
}
