import React, { useEffect, useRef, useState } from "react";
import {
  conversationAnalytics,
  dashboardInfoRequest,
} from "../../redux/features/dashboard/dashboardSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Header from "../../reuseable/header";
import User from "../../assets/User.svg";
import { FaChevronDown, FaDotCircle, FaRegUser } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import { IoTrendingUpOutline } from "react-icons/io5";
import { IoTrendingDownOutline } from "react-icons/io5";
import { IoIosArrowRoundForward } from "react-icons/io";
import "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { TbMathGreater } from "react-icons/tb";
import Table from "../../reuseable/table/Table";
import { allConversationRequest } from "../../redux/features/conversations/conversationsSlice";
import CardSkeleton from "../../components/skeletons/cardSkeleton";
import TableSkeleton from "../../components/skeletons/tableSkeleton";
import ConversationsChart from "./chart";
import BreakdownCard from "./chart/breakdown";
import RatingBar from "./ratingBar/ratingbar";
import AgentConversations from "./agentConversation";
import CustomSelect from "./agentConversation/customSelect";
import Cookies from "js-cookie";

export default function DashboardPage({ onSelect }) {
  // const [graphData, setGraphData] = useState([]);
  // const [activeGraphData, setActiveGraphData] = useState([]);
  // const [closedGraphData, setClosedGraphData] = useState([]);
  const [originalGraphData, setOriginalGraphData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelsDates, setLabelsDates] = useState([]); // date-string[]
  const [activityCardsData, setActivityCardsData] = useState([]);
  // const [graphLabels, setGraphLabels] = useState([]);

  const [timeRange, setTimeRange] = useState("7 days");
  const isMounted = useRef(false);
  // const [graph, setGraph] = useState();

  // const [renderGraph, setRenderGraph] = useState(false);
  const [activeConvo, setActiveConvo] = useState([]);
  const [isLoadingConvo, setIsLoadingConvo] = useState(false);
  const [selectedAgentEmail, setSelectedAgentEmail] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
  const [activeConversations, setActiveConversations] = useState(0);
  const [uniqueConversation, setUniqueConversation] = useState(0);
  const [closedConversations, setClosedConversations] = useState(0);
  const [closedChange, setClosedChange] = useState(0);
  const profileState = useSelector((state) => state.profileReducer);
  const dashboardState = useSelector((state) => state.dashboardReducer);

  const teamData = JSON.parse(localStorage.getItem("tm"));

  const orgId = Cookies.get("orgId") || "";
  const dispatch = useDispatch();

  const optionsForMonths = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
  ];

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    // getDashboardInfo();
    getConvo();
    getConvoList();
  }, []);

  // useEffect(() => {
  //   const activeData = extractValuesFromData(
  //     filterData(timeRange, "active", originalGraphData),
  //     timeRange
  //   );
  //   setActiveGraphData(activeData);

  //   const closedData = extractValuesFromData(
  //     filterData(timeRange, "closed", originalGraphData),
  //     timeRange
  //   );
  //   setClosedGraphData(closedData);
  // }, [timeRange, originalGraphData]);

  const getConvo = async () => {
    const data = {
      organisation_id: orgId,
      agent_email: selectedAgentEmail,
      month: selectedMonth,
      year: selectedYear,
    };

    setIsLoadingConvo(true);
    await dispatch(conversationAnalytics(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          // console.log(res?.payload?.data);
          const analyticsData = res?.payload?.data;
          const activeConversations =
            analyticsData?.conversation_metrics?.active_conversations || 0;
          const closedConversations =
            analyticsData?.conversation_metrics?.closed_conversations || 0;
          const uniqueConversation = analyticsData?.unique_customers_count || 0;

          setActiveConversations(activeConversations);
          setUniqueConversation(uniqueConversation);
          setClosedConversations(closedConversations);

          setActivityCardsData([
            {
              title: "ALL CONVERSATIONS",
              data:
                parseInt(activeConversations) + parseInt(closedConversations),
            },
            { title: "ACTIVE CONVERSATIONS", data: activeConversations },
            { title: "CLOSED CONVERSATIONS", data: closedConversations },
            { title: "UNIQUE CUSTOMERS", data: uniqueConversation },
          ]);
        } else {
          console.error(res?.payload?.data?.response);
        }
      })
      .catch((err) => {
        console.error("Error fetching conversation analytics:", err);
      })
      .finally(() => {
        setIsLoadingConvo(false);
      });
  };

  // useEffect(() => {
  //   const activeData = extractValuesFromData(
  //     filterData(timeRange, "active", originalGraphData),
  //     timeRange
  //   );
  //   setActiveGraphData(activeData);

  //   const closedData = extractValuesFromData(
  //     filterData(timeRange, "closed", originalGraphData),
  //     timeRange
  //   );
  //   setClosedGraphData(closedData);
  // }, [timeRange, originalGraphData]);

  const ActivityCard = ({ title, number, percentage, icons, statType }) => {
    return (
      <div className="w-full   mb-3">
        <div className=" bg-white border-[0.4px] shadow-custom border-[#D9D9D999] border-opacity-[60%] rounded-xl justify-between py-7 px-3">
          <div className="flex items-center justify-between">
            <div className="left-1 flex items-start justify-between">
              <span className="px-2 py-2 mt-1 border rounded mr-3">
                <img src={User} alt="user" />
              </span>
              <div className="">
                <small className="text-[#868C98] font-inter text-[11px] tracking-[2%] !capitalize mb-0">
                  {title}
                </small>
                <h2 className="text-[26px] text-[#101010] font-PJ-semibold leading-[31.2px] tracking-[-2%] ">
                  {number}
                </h2>
              </div>
            </div>
            <div className="right-1">
              <span className="bg-gray-100 px-2 py-1 rounded-full text-gray-800 border flex items-center">
                <span className="text-xs font-PJ-semibold"> {percentage} </span>
                <span className="ml-2"> {icons} </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filterData = (period, typeOfConvo, optionalData = []) => {
    const currentDate = new Date();
    let filteredData = [];
    let data = [];

    if (optionalData.length)
      data = optionalData.filter((entry) =>
        entry?.type?.toLowerCase()?.includes(typeOfConvo)
      );
    else
      data = originalGraphData.filter((entry) =>
        entry?.type?.toLowerCase()?.includes(typeOfConvo)
      );

    switch (period) {
      case "12 months":
        const twelveMonthsAgo = new Date(currentDate);
        twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);
        filteredData = data.filter(
          (entry) => new Date(entry.date) >= twelveMonthsAgo
        );
        break;
      case "30 days":
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        filteredData = data.filter(
          (entry) => new Date(entry.date) >= thirtyDaysAgo
        );
        break;
      case "7 days":
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        filteredData = data.filter(
          (entry) => new Date(entry.date) >= sevenDaysAgo
        );
        break;
      default:
        filteredData = data;
    }
    return filteredData;
  };

  const handleTimeRangeSelected = (timeRange) => {
    setTimeRange(timeRange);
    handleLabelSelection(timeRange);
  };

  // useEffect(() => {
  //   setActiveGraphData(
  //     extractValuesFromData(filterData(timeRange, "active"), timeRange)
  //   );
  //   setClosedGraphData(
  //     extractValuesFromData(filterData(timeRange, "closed"), timeRange)
  //   );
  // }, [timeRange, originalGraphData]);

  const handleLabelSelection = (timeRange) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dotw = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
    const days = [];
    const now = new Date().getTime();
    const _labelsDates = [];

    if (timeRange === "12 months") {
      const m = [];
      for (let i = 0; i < 12; i++) {
        const pastDate = new Date();
        pastDate.setMonth(pastDate.getMonth() - i);
        m[i] = months[pastDate.getMonth()];
        _labelsDates[i] = pastDate.toLocaleDateString("en-US");
      }
      setLabels(m.reverse());
      setLabelsDates(_labelsDates.reverse());
    } else if (timeRange === "30 days") {
      // get days between now and 30 days ago
      // get date for every 24 hours
      for (let i = 0; i < 30; i++) {
        const rawDate = 1000 * 60 * 60 * 24 * i;
        const dateLabel = `${
          months[new Date(now - rawDate).getMonth()]
        } ${new Date(now - rawDate).getDate()}`;
        days[i] = dateLabel;
        _labelsDates[i] = new Date(now - rawDate).toLocaleDateString("en-US");
      }
      setLabels(days.reverse());
      setLabelsDates(_labelsDates.reverse());
    } else if (timeRange === "7 days") {
      // get days between now and 7 days ago
      // get date for every 24 hours
      for (let i = 0; i < 7; i++) {
        const rawDate = 1000 * 60 * 60 * 24 * i;
        const date = new Date().getTime() - rawDate;
        const dateLabel = `${dotw[new Date(date).getDay()]}`;
        days[i] = dateLabel;
        _labelsDates[i] = new Date(date).toLocaleDateString("en-US");
      }
      setLabels(days.reverse());
      setLabelsDates(_labelsDates.reverse());
    } else {
      return months;
    }
  };

  const columns = [
    {
      Header: "S/N",
      // accessor: "",
      Cell: (row) => (
        <div className="font-PJ-bold font-medium text-[12px] text-[#424242]">
          {row.row.index + 1}
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "name",
      Cell: (row) => (
        <div className="flex flex-col sm:flex-row items-center gap-4">
          <div
            className="w-10 h-10 flex items-center justify-center rounded-full"
            style={{ backgroundColor: "#F7E6FF" }}
          >
            <span className="font-semibold text-sm">
              {row.cell.value.split(" ")[0].charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <div className="text-black font-semibold text-base">
              {row.cell.value || "N/A"}
            </div>
            <div className="text-[#424242] font-inter text-[12px] lowercase">
              {row.cell.row.original.email || "N/A"}
            </div>
          </div>
        </div>
      ),
    },
    {
      Header: "Reference",
      accessor: "session_id",
      Cell: (row) => (
        <div className="font-inter text-[12px] text-[#424242]">
          {row.cell.value}
        </div>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-[12px]">
          {row.cell.value}
        </div>
      ),
    },
    {
      Header: "Time Created",
      accessor: "time_created",
      Cell: (row) => (
        <div className="font-PJ-medium font-medium text-[12px]">
          {new Date(row.cell.value).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })}
        </div>
      ),
    },
    {
      // Header: "",
      accessor: "action",
      Cell: (row) => (
        <button className=" text-black font-bold py-2 px-4 rounded focus:outline-none ">
          <i className="ri-more-fill me-2 ri-xl"></i>
        </button>
      ),
    },
  ];

  // ACTIVE CONVERSATIONS
  const getConvoList = () => {
    dispatch(allConversationRequest())
      .then((res) => {
        if (res?.payload?.status !== 200)
          toast.error(res?.payload?.data?.detail);

        if (res?.payload.data.length)
          setActiveConvo(res.payload.data.filter((m) => m.status === "active"));
        setIsLoadingConvo(false); // Set loading to false when data is loaded or if there's an error
      })
      .catch((err) => {
        setIsLoadingConvo(false); // Ensure loading is set to false even if there's an error
      });
  };

  const extractValuesFromData = (data, tRange) => {
    const values = [];
    const convertedLabels = [];

    labelsDates.forEach((l, i) => {
      const parts = labelsDates[i].split("/");
      const formattedLabel = `${parts[0]}-${parts[2]}`;
      convertedLabels[i] = formattedLabel;
    });

    if (labelsDates.length === 0) return values;

    data.forEach((entry) => {
      const entryDate = new Date(entry.date);
      const entryMonth = entryDate.getMonth() + 1;
      const entryYear = entryDate.getFullYear();

      if (tRange.toLowerCase() === "12 months") {
        const monthAndYear = `${entryMonth}-${entryYear}`;
        const index = convertedLabels.indexOf(monthAndYear);
        if (index >= 0) {
          values[index] = parseInt(values[index] || 0) + parseInt(entry.value);
        }
      } else {
        const entryD = entryDate.toLocaleDateString("en-US");
        const index = labelsDates.indexOf(entryD);
        // console.log(index, labelsDates[index], entryD);
        if (index >= 0) values[index] = entry.value;
      }
    });
    return values;
  };

  return (
    <>
      <div className="page-desc mt-20"></div>
      <div className="middleCard px-4">
        <div className="">
          <div className="activityOverview">
            <h3>Activity Overview</h3>
            <div className="flex gap-2">
              <button disabled className="dateBut">
                Jan 02, 2023 - May 02,2023 <FaChevronDown />
              </button>
              <button disabled className="exportButton">
                <LuDownload />
                Export CSV
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-[8px] mt-4">
          {activityCardsData.length
            ? activityCardsData.map((val, index) => (
                <ActivityCard
                  key={index}
                  title={val.title}
                  number={val.data}
                  percentage={"N/A"}
                  icons={
                    <IoTrendingUpOutline
                      size={12}
                      className="flex justify-center items-center"
                    />
                  }
                />
              ))
            : [0, 0, 0, 0].map((element) => <CardSkeleton />)}
          {/* <ActivityCard
            title={"Active Conversations"}
            number={"64"}
            statType="decrease"
            percentage={"12%"}
            icons={
              <IoTrendingDownOutline
                color="red"
                size={12}
                className="flex justify-center items-center"
              />
            }
          />
          <ActivityCard
            title={"Closed Conversations"}
            number={"05"}
            statType={"percentage"}
            percentage={"76%"}
            icons={
              <IoTrendingUpOutline
                size={12}
                className="flex justify-center items-center"
              />
            }
          />
          <ActivityCard
            title={"Unique Customers"}
            number={"60"}
            // statType={"decrease"}
            percentage={"76%"}
            icons={
              <IoTrendingUpOutline
                size={12}
                className="flex justify-center items-center"
              />
            }
          /> */}
        </div>

        <div className="mt-5">
          <AgentConversations />
        </div>

        <div className="graphCard mt-11 ">
          <div className=" flex justify-between items-center">
            <div className="mb-8">
              <h1 className="text-2xl font-semiboldInter ">
                New conversations
              </h1>
            </div>
            <div className="flex justify-between items-center gap-10 mb-3">
              <p className="flex items-center gap-2">
                <FaDotCircle color="blue" />
                Conversations
              </p>
              <CustomSelect
                options={optionsForMonths}
                className="h-2" // Adjust the height using Tailwind class
              />
            </div>
          </div>
          <div className="">
            <BreakdownCard />
          </div>
          <div className="w-full  ">
            <div className="">
              <ConversationsChart
                activeConversations={activeConversations}
                closedConversations={closedConversations}
              />
            </div>
          </div>
        </div>
        {/* <div className="mt-4">
          <RatingBar />
        </div> */}

        <div className="mt-5 px-md-4 py-4 mb-4">
          <div className="">
            <div className="page-desc activeTitle flex justify-between">
              <h3>Active Conversations</h3>

              <Link
                to={`/messages`}
                className="text-blue-500 hover:text-blue-700"
              >
                <p className="flex gap-3">
                  View more <IoIosArrowRoundForward size={20} />{" "}
                </p>
              </Link>
            </div>
            <div className="">
              <div className="overflow-x-auto">
                {isLoadingConvo ? (
                  <TableSkeleton />
                ) : activeConvo.length ? (
                  <div className="">
                    <div className="">
                      <Table
                        columns={columns}
                        data={activeConvo.slice(0, 10)}
                        currentPage={0}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-10 text-gray-500">
                    No active conversation available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
