import React from "react";
import { IoTrendingDown, IoTrendingUp } from "react-icons/io5";
import { RiArrowLeftDownFill, RiArrowLeftUpFill } from "react-icons/ri";

const BreakdownCard = () => {
  const data = [
    { name: "Apr 29", conversations: 10 },
    { name: "May 6", conversations: 18 },
    { name: "May 13", conversations: 22 },
    { name: "May 20", conversations: 25 },
    { name: "May 27", conversations: 16 },
    { name: "May 30", conversations: 26 },
    { name: "June 27", conversations: 10 },
  ];

  const totalConversations = data.reduce(
    (total, item) => total + item.conversations,
    0
  );

  const inboundConversations = totalConversations * 0.883; // 88.3%
  const outboundConversations = totalConversations * 0.12; // 12%

  const previousInboundPercentage = 95.1; // Example previous value
  const previousOutboundPercentage = 5.9; // Example previous value

  const inboundChangePercentage = (
    ((88.3 - previousInboundPercentage) / previousInboundPercentage) *
    100
  ).toFixed(1);

  const outboundChangePercentage = (
    ((12 - previousOutboundPercentage) / previousOutboundPercentage) *
    100
  ).toFixed(1);

  const renderChange = (changePercentage) => {
    const isPositive = changePercentage >= 0;
    const sign = isPositive ? "+" : "-";
    const colorClass = isPositive ? "text-[#176448] text-[11px] font-inter leading-[12px] tracking-[2%] bg-[#CBF5E5]" : "text-red-600 bg-red-100 text-[11px] font-inter leading-[12px] tracking-[2%]";
    // const Icon = isPositive ? IoTrendingUp : IoTrendingDown;

    return (
      <span className={`ml-2 ${colorClass} flex items-center rounded-full px-2 py-1`}>
        {sign}
        {/* <Icon className="ml-1" /> */}
        {Math.abs(changePercentage)}%
      </span>
    );
  };

  return (
    <div className=" ">
      <hr className="p-4" />
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-4">
          <div className="border-2 border-[#E2E4E9] rounded-full w-10 h-10 flex justify-center items-center">
            <RiArrowLeftDownFill size={20} color="#375DFB" />
          </div>
          <div>
            <p className="text-lg font-inter text-[#868C98]">Inbound</p>
            <p className="text-lg font-semibold  flex items-center">
              88.3%
              {renderChange(inboundChangePercentage)}
            </p>
          </div>
        </div>

        {/* Vertical Line */}
        <div className="h-10 border-l border-gray-300 mx-6"></div>

        <div className="flex justify-start gap-4">
          <div className="border-2 border-[#E2E4E9] rounded-full w-10 h-10 flex justify-center items-center">
            <RiArrowLeftUpFill size={20} color="#35B9E9" />
          </div>
          <div>
            <p className="text-lg font-inter text-[#868C98]">Outbound</p>
            <p className="text-lg font-semibold  flex items-center">
              12%
              {renderChange(outboundChangePercentage)}
            </p>
          </div>
        </div>
      </div>

      <hr className="mt-4" />
    </div>
  );
};

export default BreakdownCard;
